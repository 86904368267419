.head-search {
  max-width: 296px;

  .anticon-search{
    color: white!important;
  }

  input[type="search"] {
    &::placeholder {
      text-transform: capitalize;
    }
  }
}
