.multi-select-dropdown {
  .ant-dropdown-menu-item-group {
    .ant-dropdown-menu-item-group-title {
      padding: 0 !important;
    }
  }

  .ant-dropdown-menu {
    padding: 0 !important;

    .ant-dropdown-menu-item {
      padding: 0 !important;
    }
  }
}
