.app-tag {
	background-color: white;
  	border-radius: 4px;
  	color: white;
  	display:inline-block;
  	font-size: 12px;
  	font-weight: 700;
  	line-height: 1;
  	padding: 4px 15px;
  	&.no-margin {
  		top: 0;
  	}
}
