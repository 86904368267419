.error-403 {
  text-align: center;
  padding: 50px;

  .error-title {
    font-size: 36px;
    font-weight: bold;
    margin: 25px;
  }
}
